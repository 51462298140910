// var config = require( './config.js' );




	imagesLoaded('.glitch__img', { background: true }, () => {
		document.body.classList.remove('loading');
		document.body.classList.add('imgloaded');
    document.getElementById("loader").classList.add('done');
    document.getElementById("first__img").classList.add('done');

	});



var controller = new ScrollMagic.Controller();

new ScrollMagic.Scene({
  triggerElement: '#js__T02',
	offset: -250
})
.setClassToggle('#js__T02', "active")
.addTo(controller);

new ScrollMagic.Scene({
  triggerElement: '#js__T03',
	offset: -250
})
.setClassToggle('#js__T03', "active")
.addTo(controller);

new ScrollMagic.Scene({
  triggerElement: '#js__T04',
	offset: -250
})
.setClassToggle('#js__T04', "active")
.addTo(controller);

new ScrollMagic.Scene({
  triggerElement: '#js__T05',
	offset: -250
})
.setClassToggle('#js__T05', "active")
.addTo(controller);

new ScrollMagic.Scene({
  triggerElement: '#js__T06',
	offset: -250
})
.setClassToggle('#js__T06', "active")
.addTo(controller);

new ScrollMagic.Scene({
  triggerElement: '#js__T07',
	offset: -250
})
.setClassToggle('#js__T07', "active")
.addTo(controller);

new ScrollMagic.Scene({
  triggerElement: '#js__T08',
	offset: -250
})
.setClassToggle('#js__T08', "active")
.addTo(controller);

new ScrollMagic.Scene({
  triggerElement: '#js__T09',
	offset: -250
})
.setClassToggle('#js__T09', "active")
.addTo(controller);

new ScrollMagic.Scene({
  triggerElement: '#js__T10',
	offset: -250
})
.setClassToggle('#js__T10', "active")
.addTo(controller);

new ScrollMagic.Scene({
  triggerElement: '#js__T11',
	offset: -250
})
.setClassToggle('#js__T11', "active")
.addTo(controller);

new ScrollMagic.Scene({
  triggerElement: '#js__T12',
	offset: -250
})
.setClassToggle('#js__T12', "active")
.addTo(controller);

new ScrollMagic.Scene({
  triggerElement: '#js__T13',
	offset: -250 
})
.setClassToggle('#js__T13', "active")
.addTo(controller);
